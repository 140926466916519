<script>
import ColorSchemeToggle from '@saulhardman/vue-color-scheme-toggle';

import SiteLogo from '~/assets/img/site-logo-monotone.svg';
import { trackGoal, TOGGLE_COLOR_SCHEME } from '~/assets/js/analytics';

export default {
  components: {
    ColorSchemeToggle,
    SiteLogo,
  },

  computed: {
    isHomepage() {
      return this.$route.path === '/';
    },
  },

  methods: {
    handleColorSchemeToggleClick() {
      trackGoal(TOGGLE_COLOR_SCHEME);
    },
  },

  render(h, { data = {} } = {}) {
    const headerAttrs = {
      ...data,
      class: 'c-site-header relative',
      attrs: { id: 'js-site-header', 'data-qa': 'site-header' },
    };

    // const siteLogo = h('site-logo', {
    //   class: 'c-site-header__site-logo',
    //   attrs: {
    //     width: 32,
    //     height: 32,
    //     role: 'img',
    //     'aria-label': 'Right-Click &rarr; View Page Source Logo',
    //   },
    // });

    const title = h(this.isHomepage ? 'h1' : 'p', {
      class: 'c-site-header__title',
      domProps: { innerHTML: 'Right-Click &rarr; View Page Source' },
    });

    const coloSchemeToggle = h('color-scheme-toggle', {
      class: 'c-site-header__color-scheme-toggle',
      nativeOn: {
        click: this.handleColorSchemeToggleClick,
      },
    });

    if (this.isHomepage) {
      return h('header', headerAttrs, [
        /* siteLogo,  */ title,
        coloSchemeToggle,
      ]);
    }

    return h('header', headerAttrs, [
      h(
        'nuxt-link',
        {
          props: { to: '/' },
          class: 'c-site-header__link',
        },
        [/* siteLogo,  */ title],
      ),
      coloSchemeToggle,
    ]);
  },
};
</script>

<style lang="postcss">
.c-site-header {
  height: 40px;
}

.c-site-header,
.c-site-header__link {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.c-site-header__link {
  position: relative;
  color: var(--color-black);
  text-decoration: none;

  @media (prefers-color-scheme: dark) {
    color: var(--color-ghost);
  }

  &:focus-visible {
    outline: 3px solid var(--color-gold);
    outline-offset: var(--spacing-2);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  @nest .t-light & {
    color: var(--color-black);
  }

  @nest .t-dark & {
    color: var(--color-ghost);
  }
}

/* .c-site-header__site-logo {
  display: none;
  margin-right: var(--spacing-4);
  text-decoration: none;

  @media (--alpha) {
    display: initial;
  }

  @media (--delta) {
    box-sizing: content-box;
    position: absolute;
    right: 100%;
    padding: var(--spacing-5);
  }
} */

.c-site-header__title {
  margin: 0;
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-semi-bold);
}

.c-site-header__color-scheme-toggle {
  margin-left: auto;
  right: calc(-1 * var(--spacing-3));
}
</style>
